//引入vue-router路由插件
import VueRouter from "vue-router";
//引入Vue
import Vue from "vue";
Vue.use(VueRouter);

//引入路由组件
import Game from '@/view/game/index.vue'
import Career from '@/view/career/index.vue'
import Contact from '@/view/contact/index.vue'
import PrivacyPolicy from '@/view/privacypolicy/index.vue'
import TermsAndConditions from '@/view/termsandconditions/index.vue'
// import ResponsibleGameplayPolicy from '@/view/responsiblegameplaypolicy/index.vue'
// import GameStatement from '@/view/gamestatement/index.vue'
// import SweepsRules from '@/view/sweepsrules/index.vue'



const router = new VueRouter({
    mode: 'history',
    //配置路由
    routes:[
        {
            name:'game',
            path: '/game',
            component: Game, 
        },
        {
            name:'careed',
            path: '/careed',
            component: Career,
        },
        {
            name:'contact',
            path: '/contact',
            component: Contact,
        },
        {
            name:'privacypolicy',
            path: '/datum',
            component: PrivacyPolicy,
        },
        {
            name:'termsandconditions',
            path: '/termcondi',
            component: TermsAndConditions,
        },
        // {
        //     name:'renzaresponsiblegameplaypolicy',
        //     path: '/resp',
        //     component: ResponsibleGameplayPolicy,
        // },
        // {
        //     name:'renzagamestatement',
        //     path: '/game_stat',
        //     component: GameStatement,
        // },
        // {
        //     name:'renzasweepsrules',
        //     path: '/ssr_url',
        //     component: SweepsRules,
        // },
        {
            path: '*',
            redirect: '/game',
        }
    ]
  })


export default router

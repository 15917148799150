<template>
  <div class="header">
      <!-- 顶部导航栏 -->
      <div class="top-nav sticky">
        <div style=" width: .959rem; height: .3646rem;">
          <!-- <img src="@/assets/image/top-logo.png" style=" padding-top: .0781rem; width: .6986rem; height: .2604rem" alt="" /> -->
        </div>
        <div>
          <el-menu :default-active="message" class="el-menu-demo" mode="horizontal"
            background-color="#ED9A21" text-color="#172532"   active-text-color="#ED9A21">
            <el-menu-item index="3" :class="message==='3'?'active':''">
              <router-link class="list-group-item" active-class="active" :to="'/game'" style="text-decoration: none;">Game</router-link>
            </el-menu-item>
            <el-menu-item index="4" :class="message==='4'?'active':''">
              <router-link class="list-group-item" active-class="active" :to="'/careed'" style="text-decoration: none;">Career</router-link>
            </el-menu-item>
            <el-menu-item index="5" :class="message==='5'?'active':''">
              <router-link class="list-group-item" active-class="active" :to="'/contact'" style="text-decoration: none;">contact</router-link>
            </el-menu-item>
          </el-menu>
        </div>
        <div class="top-nav-right">
          <img src="../assets/image/icon_right.png" alt="">
          <!-- <i class="iconfont icon-lianshu"></i>
          <i class="iconfont icon-a-logo-youtobe"></i> -->
        </div>
      </div>
    </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Header',
  props: {
    message: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      scrollTop: 0,  
      headerHeight: 0, 
    }
  },  
}
</script>

<style lang="scss" scoped>
@import url(../fontCss/fontCss.css);
.header {
  transition: top 1s ease; 
  background-color: #ED9A21;
  width: 100%;
  height: .3646rem;
  position: fixed;
  top: 0;
  z-index: 999;
}
.top-nav {
  display: flex;
  justify-content: space-evenly;
  width: 8.6979rem;
  margin: auto;
  height: 100%;
}
.el-menu-item{
  a{
    font-size: .1042rem;
  }
}
.el-menu-item{
  border-bottom-color: rgb(190, 123, 26) !important;
  
}

.el-menu.el-menu--horizontal {
    border-bottom: none;
}
.el-menu-demo{
  height: .2604rem !important;
  width: 5.2083rem !important;
  padding-top: .0521rem;
  font-size: .0833rem !important;
  font-weight: bold;
  font-family:'SVN-Avant-book';
}
.el-menu--horizontal>.el-menu-item {
  height: .3125rem;
  line-height: .3125rem;
}
.el-menu-vertical {
  height: .2604rem !important; /* 设置导航栏的高度 */
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):focus, .el-menu--horizontal .el-menu-item:not(.is-disabled):hover{
  background-color: #ED9A21 !important;
  color: #ffffffc9 !important;
}
.sticky {
  position: sticky;
  top: 0;
  left: 0;
}
.active>a{
  background-color: #853125;
  padding: .026rem;
  border-radius: .026rem;
}
.top-nav-right{
  padding-top: .0833rem;
  img{
    width: .3021rem;
    height: auto;
  }
  // i{
  //   margin-right: .026rem;
  // }
}
</style>
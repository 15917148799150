import { render, staticRenderFns } from "./footer.vue?vue&type=template&id=807e5fa6&scoped=true"
import script from "./footer.vue?vue&type=script&lang=js"
export * from "./footer.vue?vue&type=script&lang=js"
import style0 from "./footer.vue?vue&type=style&index=0&id=807e5fa6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "807e5fa6",
  null
  
)

export default component.exports